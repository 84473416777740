import { Box, Button, TextField, useTheme } from "@mui/material";
import AuthLayout from "./AuthLayout";
import { tokens } from "../../context/ThemeProvider";

function ForgotPassword() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <AuthLayout title="Forgot Password">
      <form>
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          required
        />
        <Box mt={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "1rem",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Reset Password
          </Button>
        </Box>
      </form>
    </AuthLayout>
  );
}

export default ForgotPassword;
