import { Box, Chip, IconButton, useTheme } from "@mui/material";
import Header from "../../components/Header";
import {
  GridToolbar,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { mockQueries } from "../../data/mockData";
import EditNoteIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Link } from "react-router-dom";
import { tokens } from "../../context/ThemeProvider";
import DataTable from "../../components/DataTable";

function Queries() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "user",
      headerName: "Author",
      flex: 1,
    },
    {
      field: "query",
      headerName: "Description",
      flex: 2,
      headerAlign: "center",
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, Array<string>>) => {
        console.log(params.value);
        return (
          <Box>
            {params.value &&
              params.value.map((cat) => {
                return <Chip label={cat} style={{ margin: "4px" }} />;
              })}
          </Box>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box>
            <Link to={`/query/${params.id}`}>
              <EditNoteIcon
                style={{ color: colors.greenAccent[400] }}
                sx={{ verticalAlign: "middle", mx: "7px" }}
              />
            </Link>
            <IconButton>
              <DeleteIcon style={{ color: colors.redAccent[400] }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="QUERIES" subtitle="List of current queries" />

      <DataTable
        rows={mockQueries}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
      />
    </Box>
  );
}

export default Queries;
