import { Box, IconButton, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../context/ThemeProvider";
import {
  GridColDef,
  GridToolbar,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { mockDataContacts } from "../../data/mockData";
import EditNoteIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable";

function Users() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "registrarId", headerName: "Registrar ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box>
            <Link to={`/user/${params.id}`}>
              <EditNoteIcon
                style={{ color: colors.greenAccent[400] }}
                sx={{ verticalAlign: "middle", mx: "7px" }}
              />
            </Link>
            <IconButton>
              <DeleteIcon style={{ color: colors.redAccent[400] }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="USERS" subtitle="List of current users" />
      <DataTable
        rows={mockDataContacts}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
      />
    </Box>
  );
}

export default Users;
