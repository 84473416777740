import { createContext, useState } from "react";

interface Auth {
  user?: any;
}

export const AuthContext = createContext({
  auth: {} as Auth,
  setAuth: (auth: Auth) => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}
