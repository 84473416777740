import { AuthProvider, AppThemeProvider } from "./context";
import { Route, Routes, Outlet } from "react-router-dom";
import Dashboard from "./screens/dashboard";
import TopBar from "./screens/global/Topbar";
import SideBar from "./screens/global/Sidebar";
import Team from "./screens/team";
import User from "./screens/user";
import Users from "./screens/users";
import Queries from "./screens/queries";
import { Bar, Line, Pie, Geo } from "./screens/charts";
import Query from "./screens/query";
import { ForgotPassword, Login } from "./screens/auth";
import RequireAuth from "./components/RequireAuth";
import Missing from "./screens/missing";

function Layout() {
  return (
    <div className="app">
      <SideBar />
      <main className="content">
        <TopBar />
        <Outlet />
      </main>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppThemeProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route element={<RequireAuth />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/users" element={<Users />} />
              <Route path="/user/:id" element={<User />} />
              <Route path="/queries" element={<Queries />} />
              <Route path="/query/:id" element={<Query />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/line" element={<Line />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/geo" element={<Geo />} />
            </Route>
          </Route>

          {/* Redirect all other paths to a default route */}
          <Route path="*" element={<Missing />} />
        </Routes>
      </AppThemeProvider>
    </AuthProvider>
  );
}

export default App;
