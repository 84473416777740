import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { mockQueries } from "../../data/mockData";
import Activities from "../../components/Activities";
import { tokens } from "../../context/ThemeProvider";

function Query() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { id } = useParams();
  const query = mockQueries[parseInt(id!) - 1];

  const activities = [
    `Provided a detailed solution to "How to fix 'NullPointerException' in Java?"`,
    `Upvoted a well-explained answer to a question about CSS Flexbox layout.`,
    `Downvoted an answer that suggested using an outdated and insecure method for user authentication in Node.js.`,
  ];

  return (
    <Box m="20px" display="flex">
      <Box flex="3">
        <Box>
          <Avatar sx={{ bgcolor: colors.redAccent[200] }}>
            {query.user[0].toUpperCase()}
          </Avatar>
          <Box>
            <Typography>{query.user}</Typography>
          </Box>
        </Box>
      </Box>
      <Box flex="2">
        <Activities activities={activities} />
      </Box>
    </Box>
  );
}

export default Query;
