import { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { tokens } from "../../context/ThemeProvider";
import AuthLayout from "./AuthLayout";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "../../api/axios";
import { AxiosError } from "axios";
import useAuth from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";

const LOGIN_URL = "/v1/auth/login/";

function Login() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = JSON.stringify({
      country_code: "+91", // FIXME: Hardcoded for now
      phone_number: phoneNumber,
      password: password,
    });

    setPhoneNumber("");
    setPassword("");

    try {
      const respose = await axios.post(LOGIN_URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      setAuth(respose?.data.data);
      console.log(respose?.data);
      console.log("Logged in successfully", from);
      navigate(from, { replace: true });
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err.response?.data);
      } else {
        console.log("Network Error");
      }
    }
  };

  return (
    <AuthLayout title="Login">
      <Box
        component="form"
        sx={{
          "& label.MuiInputLabel-root": {
            fontSize: "1.1rem", // Label font size
          },
          "& label.Mui-focused": {
            color: colors.blueAccent[500], // Active label color
          },
          "& .MuiOutlinedInput-root": {
            fontSize: "1.1rem", // Input text font size
            "&.Mui-focused fieldset": {
              borderColor: colors.blueAccent[500], // Active border color
            },
          },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          label="Phone Number"
          variant="outlined"
          type="tel"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={handlePasswordChange}
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box mt={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "1rem",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </AuthLayout>
  );
}

export default Login;
