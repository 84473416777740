import { Box, Typography } from "@mui/material";

interface ActivitiesProps {
  activities?: any[];
}

function Activities({ activities }: ActivitiesProps) {
  return (
    <Box>
      {activities && (
        <Box>
          <Typography variant="h2" fontWeight="fontWeightMedium">
            Latest Activities
          </Typography>
          <ul>
            {activities.map((activity) => (
              <li
                style={{
                  listStyleType: "none",
                  position: "relative",
                  width: "1px",
                  paddingTop: "50px",
                  backgroundColor: "#f45b69",
                }}
              >
                <Box
                  sx={{
                    minWidth: "480px",
                    bgcolor: "#f45b6810",
                    p: "15px",
                  }}
                >
                  <Typography variant="h4">{activity}</Typography>
                  <Typography variant="h5" style={{ paddingTop: "4px" }}>
                    2 days ago
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    left: "50%",
                    bottom: "0",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    bgcolor: "#f45b69",
                    transform: "translateX(-50%)",
                  }}
                />
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
}

export default Activities;
