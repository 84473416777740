import { Box, useTheme } from "@mui/material";
import { tokens } from "../context/ThemeProvider";
import { DataGrid, GridColDef, GridSlotsComponent } from "@mui/x-data-grid";

interface DataTableProps {
  rows: any[];
  columns: GridColDef<any>[];
  slots?: Partial<GridSlotsComponent> | undefined;
}

function DataTable({ rows, columns, slots }: DataTableProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      <DataGrid rows={rows} columns={columns} slots={slots} />
    </Box>
  );
}

export default DataTable;
