import { Box } from "@mui/material";

function Missing() {
  return (
    <Box mt="40px" display="flex" alignItems="center" justifyContent="center">
      <img src={`../../not_found.svg`} alt="cur" height="800px" />
    </Box>
  );
}

export default Missing;
