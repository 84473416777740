import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../context/ThemeProvider";
import { ReactNode } from "react";

interface AuthLayoutProps {
  title: string;
  children: ReactNode;
}

function AuthLayout({ title, children }: AuthLayoutProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      m="20px"
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      width="100%"
      height="80%"
    >
      <img
        alt="profile-user"
        width="500px"
        height="500px"
        src={`../../login.svg`}
      />
      <Box width="40%" p="30px" bgcolor={colors.primary[400]}>
        <Typography variant="h3" align="center" gutterBottom>
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
}

export default AuthLayout;
