import { Box, Divider, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { mockDataContacts } from "../../data/mockData";
import LineChart from "../../components/LineChart";
import Activities from "../../components/Activities";

function User() {
  const { id } = useParams();
  const user = mockDataContacts[parseInt(id!) - 1];

  const activities = [
    `Provided a detailed solution to "How to fix 'NullPointerException' in Java?"`,
    `Upvoted a well-explained answer to a question about CSS Flexbox layout.`,
    `Downvoted an answer that suggested using an outdated and insecure method for user authentication in Node.js.`,
  ];

  return (
    <Box m="20px" display="flex">
      <Box flex="3">
        <Header title={user.name} subtitle={user.email} />
        <Box marginBottom="20px">
          <Typography
            variant="h5"
            style={{
              marginTop: "8px",
            }}
          >
            Age: {user.age}
          </Typography>
          <Typography
            variant="h5"
            style={{
              marginTop: "8px",
            }}
          >
            Phone: {user.phone}
          </Typography>
          <Typography
            variant="h5"
            style={{
              marginTop: "8px",
            }}
          >
            Address: {user.address}
          </Typography>

          <Typography
            variant="h5"
            style={{
              marginTop: "8px",
            }}
          >
            City: {user.city}
          </Typography>
        </Box>
        <Divider />
        <Box width="800px" height="400px" px="20px">
          <LineChart isDashboard={true} />
        </Box>
      </Box>
      <Box flex="2">
        <Activities activities={activities} />
      </Box>
    </Box>
  );
}

export default User;
